import { useEffect, useState } from "react"
import { requestOptions } from "./inclassApi"

export const useInclassCourseSearch = (courseSearch) => {
  const [courseFilter, setCourseFilter] = useState()
  const [courseAmount, setCourseAmount] = useState()
  const [ isLoading, setIsLoading ] = useState(false)

  useEffect(() => {
    async function getCourseSearch(courseSearch, options) {
      setIsLoading(true)
      const response = await fetch(process.env.REACT_APP_INCLASS_ENDPOINT + "/public-api/courses/?search=" + courseSearch, options)
      const result = await response.json()
      setCourseFilter(result.results)
      setCourseAmount(result.count)
      setIsLoading(false)
    }
    
    getCourseSearch(courseSearch, requestOptions)
  }, [courseSearch])

  return [ courseFilter, courseAmount, isLoading ]
}