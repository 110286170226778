import CourseCarousel from "./CourseCarousel";
import CourseSlider from "./CourseSlider";

import './ShopCourse.css'
import { Link } from "react-router-dom";
import RightArrowIcon from "../svg/rightArrowIcon";

function ShopCourse(props) {
  const { courseData, courseAmount } = props;

  return (
    <div className="course-section">
      {courseData && <CourseCarousel courseData={courseData} trialButton={"show"} />}
      {courseData && <CourseSlider courseData={courseData} />}
      {courseAmount >= 3 ?
        <Link to="/courses" className="button-nobg-white">
          <span>คอร์สเรียนทั้งหมด</span><RightArrowIcon />
        </Link>
        : null
      }
    </div>
  )
}

export default ShopCourse;