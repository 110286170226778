import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation } from 'swiper';

import 'swiper/css'
import 'swiper/css/navigation'
import './CourseCarousel.css'
import CourseItemSalePage from './CourseItemSalePage';

function CourseCarousel(props) {
  const { courseData, trialButton } = props
  const bestSellCourses = courseData.filter(rawData => rawData.bestSeller)
  const courseBestSellAmount = bestSellCourses.length

  if (courseBestSellAmount <= 2) 
    return (
      <div className='notSwipper'>
        {bestSellCourses.map(course => {
          if (!!course.mobileImageUrl && !!course.desktopImageUrl) {
            return <CourseItemSalePage course={course} trialButton={trialButton} key={course.id}/>
          }
          return null
        })}
      </div>
    )

  return (
    <Swiper className='mySwiper'
      slidesPerView={"auto"}
      centeredSlides={true}
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false
      }}
      navigation={true}
      modules={[Autoplay, Navigation]}
      spaceBetween={20}
    >
      {bestSellCourses.map(course => {
        if (!!course.mobileImageUrl && !!course.desktopImageUrl) {
          return (
            <SwiperSlide key={course.id}>
              <CourseItemSalePage course={course} trialButton={trialButton} />
            </SwiperSlide>
          )
        }
        return null
      })}
    </Swiper>
  )
}

export default CourseCarousel;

