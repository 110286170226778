import useContentfulContactUs from "./api/ContentfulContactUs";
import Loader from "./Loading";
import ContactItem from "./contactUs/contactItem";

import './AppContactUs.css'
import { HelmetProvider } from "react-helmet-async";
import HelmetElement from "./HelmetElement";

const AppContactUs = ({isLoading, contactUsData}) => {
  
  console.log(contactUsData);
  if (isLoading) return <Loader />
  
  return ( 
    contactUsData && 
    <HelmetProvider>
      <HelmetElement title={contactUsData.pageTitle}/>
      <section className="contact-section">
        <div className="contact-container">
          <h2>{contactUsData.pageTitle}</h2>
          <div className="contact-list">
            {contactUsData.contactUsList.map((contact) => {
              const { id, title, url, icon } = contact
              return <ContactItem key={id} title={title} url={url} icon={icon}/>
            })}
          </div>
        </div>
      </section>
    </HelmetProvider>
  );
}
 
export default AppContactUs;