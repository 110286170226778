import { useEffect, useState } from "react"
import { requestOptions } from "./inclassApi"

const useInclassCourseList = (categoryParam) => {
    const activeCategory = categoryParam
    const [odmCourseList, setOdmCourseList] = useState()
    
    useEffect(() => {
        async function getOdmCourseList(options, categoryId) {
            if(categoryId) {
                try {
                    const response = await fetch(process.env.REACT_APP_INCLASS_ENDPOINT + "/public-api/courses/?page_size=200&categories=" + categoryId, options)
                    const result = await response.json()
                    const courseList = result.results
                    setOdmCourseList(courseList)
                } catch (error) {
                    console.log(error);
                }
            }        
        }
        
        getOdmCourseList(requestOptions, activeCategory)
    }, [activeCategory])

    return odmCourseList
}
 
export default useInclassCourseList;