import CoverPage from "./salePage/CoverPage";
import SellPoint from "./salePage/SellPoint";
import Testimonial from "./salePage/Testimonial";
import ShopCourse from "./salePage/ShopCourses";
import Loader from "./Loading";
import { Helmet, HelmetProvider } from "react-helmet-async";

function AppSalePage(props) {
  const { isLoading, salePageData, courseData, courseAmount } = props;

  // Detach Data
  const {
    academicName,
    coverPageUrl,
    sellingPointTitle,
    sellingPoints,
    testimonialTitle,
    testimonials,
  } = salePageData;

  let loadingElement = null;
  if (isLoading) {
    loadingElement = <Loader />;
  }

  return (
    <HelmetProvider>
      <Helmet prioritizeSeoTags>
        <title>
          {process.env.REACT_APP_ACADEMIC_NAME} |{" "}
          {process.env.REACT_APP_ACADEMIC_MOTTO}
        </title>
      </Helmet>
      <div>
        {loadingElement}
        <CoverPage coverPageUrl={coverPageUrl} academicName={academicName} />
        <SellPoint
          sellingPointTitle={sellingPointTitle}
          sellingPoints={sellingPoints}
        />
        <ShopCourse courseData={courseData} courseAmount={courseAmount} />
        {testimonials && (
          <Testimonial
            testimonialTitle={testimonialTitle}
            testimonials={testimonials}
          />
        )}
      </div>
    </HelmetProvider>
  );
}

export default AppSalePage;
