import { ExternalLink } from "react-external-link";
import './contactItem.css'
import RightArrowIcon from "../svg/rightArrowIcon";

const ContactItem = (props) => {
  const { title, url, icon} = props

  return (
      <ExternalLink href={url}>
      <div className="contact-button">
        <div className="icon-container">
          <img className="icon" src={icon} alt={title} />
        </div>
        <p className="title">{title}</p>
        <div className="arrow-container"><RightArrowIcon /></div>
      </div>
    </ExternalLink>
  );
}
 
export default ContactItem;