import { HelmetProvider } from "react-helmet-async";
import { useParams } from "react-router-dom";
import { ButtonSquarePrimary, ButtonSquareWhite } from "../elements/button";
import CourseNotFound from "./404/CourseNotFound";
import { useInclassCourseDetail } from "./api/inclassCourseDetail";

import './AppCourseDetail.css'
import CourseCard from "./courseDetail/CourseCard";
import CourseContents from "./courseDetail/CourseContents";
import HelmetElement from "./HelmetElement";
import Loader from "./Loading";
import { PlayIcon } from "./svg/playIcon";
import { RegisterIcon } from "./svg/registerIcon";

const AppCourseDetail = (props) => {
  const { id } = useParams()
  const { fbMessengerLink } = props
  const [courseData, isLoading] = useInclassCourseDetail(id)

  let courseDetailElement = null
  if (!!courseData && courseData.detail !== 'Not found.') {
    const { id, table_of_content, detail, image, name, price, default_hours, term, trial_link, online_playlist } = courseData;
    const haveOnlinePlaylist = online_playlist.items.length > 0
    courseDetailElement = (
      <div className="course-content ">
        <h1 className="page-header">วิชา {name}</h1>
        <CourseCard
          imageUrl={image}
          name={name}
          defaultHour={default_hours / 60}
          detail={detail}
          price={Math.round(price)}
          term={term.name}
        />
        <div className="course-detail-button">
          { haveOnlinePlaylist && 
            <ButtonSquareWhite 
              url={trial_link} 
              text="ทดลองเรียน" 
              preIcon={<PlayIcon />} 
              isCenter="true" 
              fullWidth="true"
            />
          }
          <ButtonSquarePrimary 
            url={process.env.REACT_APP_ACADEMIC_INCLASS_URL + "/store/detail/" + id} 
            text="สมัครเรียน" 
            preIcon={<RegisterIcon />} 
            isCenter="true" 
            fullWidth="true"
          />
        </div>
        <CourseContents
          courseContents={table_of_content}
        />
      </div>
    )
  } else if (!!courseData) {
    courseDetailElement = <CourseNotFound fbMessengerLink={fbMessengerLink} />
  }

  let loadingElement = null
  if (isLoading) {
    loadingElement = (
      <Loader />
    )
  }

  return (
    <HelmetProvider>
      {courseData && <HelmetElement title={`วิชา ${courseData.name}`} />}
      <section>
        {loadingElement}
        {courseDetailElement}
      </section>
    </HelmetProvider>
  );
}

export default AppCourseDetail;