import { useEffect } from "react";
import { Link, useSearchParams } from "react-router-dom";
import useInclassCategoriesList from "../api/inclassCategoriesList";
import useInclassCourseList from "../api/inclassCourseList";
import CourseItem from "./CourseItem";

const AllCourses = () => {

  const [searchParam, setSearchParam] = useSearchParams()
  const categoryParam = searchParam.get('categories')

  const inclassCategories = useInclassCategoriesList()
  const inclassCourseList = useInclassCourseList(categoryParam)

  function handleClick(categoryId) {
    setSearchParam({ categories: categoryId })
  }

  useEffect(() => {
    if (!!inclassCategories && !categoryParam) {
      setSearchParam({categories: inclassCategories[0].id})
    }
    
  }, [inclassCategories, categoryParam])

  return (
    
        <div>
          <div className="tab">
            <ul>
              {inclassCategories &&
                inclassCategories
                  .map((category, index) => {
                    const { id, name } = category
                    return (
                      <li key={index} onClick={() => handleClick(id)} className={id === Number(categoryParam) ? "is-active" : ""}>{name}</li>
                    )
                  }
                  )
              }
            </ul>
          </div>

          <div className="grid grid-ipad-3">
            {inclassCourseList && inclassCategories &&
              inclassCourseList
                .map(course => {
                  const { id, image, name, default_hours, detail, price, term } = course
                  return (
                    <Link to={`/courses/${id}`} key={id}>
                      <CourseItem
                        imageUrl={image}
                        name={name}
                        defaultHour={default_hours / 60}
                        detail={detail}
                        price={Math.round(price)}
                        term={term.name}
                      />
                    </Link>
                  )
                })
            }
          </div>
        </div>
  );
}

export default AllCourses;