import './Testimonial.css'

function Testimonial(props) {
    const { testimonialTitle, testimonials } = props
    
    const renderGridDesktop = () => {
        let amount = testimonials.length
        
        if (amount >= 3) return 3
        if (amount === 2) return 2
        return 1
    }

    const testmonialElement = testimonials.map((testimonail) => {
        return (
            <div className='testimonial' key={testimonail.id}>
                <p className='testimonial-review'>{testimonail.review}</p>
                <div className='testimonial-info'>
                    {<h4>{testimonail.name}</h4>}
                    {testimonail.avatarUrl && <img src={testimonail.avatarUrl} alt={testimonail.name}/>}
                </div>     
            </div>
        )
    })

    return (
        <section className='testimonial-section'>
            <div className='container sale-container'>
                <h2>{ testimonialTitle }</h2>
                <div className={`grid grid-desktop-${renderGridDesktop()}`}>
                    { testmonialElement }
                </div>
            </div>
        </section>
    )
}

export default Testimonial;