import { ExternalLink } from 'react-external-link';
import { Link, useParams } from 'react-router-dom'
import HelmetElement from '../HelmetElement';
import CourseNotFoundIcon from '../svg/couseNotFoundIcon';
import MessengerIcon from '../svg/messengerIcon';
import SearchIcon from '../svg/searchIcon';

import './CourseNotFound.css'


const CourseNotFound = (props) => {
  const { id } = useParams()
  const { fbMessengerLink } = props

  return (
    <section className='course-not-found'>
      <HelmetElement title={`ไม่พบวิชาเรียน ${id}`}/>
      
      <div className='course-not-found-container'>
        <CourseNotFoundIcon courseId={id} />
        <h1>ไม่พบวิชาเรียน</h1>
        <div className='button-group'>
          <Link to="/searchcourse"><button className='button button-primary'>ค้นหาคอร์สเรียน <SearchIcon /></button></Link>
          <ExternalLink href={fbMessengerLink}><button className='button button-thirdary'>สอบถามแอดมิน <MessengerIcon/></button></ExternalLink>
        </div>
      </div>
    </section>
  );
}
 
export default CourseNotFound;