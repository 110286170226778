import { ExternalLink } from 'react-external-link';
import './Footer.css'

const Footer = (props) => {
    const { salePageData } = props
    const { academicName, facebookName, facebookLink, lineName, lineLink, phoneNo } = salePageData
    return ( 
        <footer className="footer">
            <div className="container">
                <div className="column is-12">
                    <div className="columns">
                        <div className="column is-6">
                            <p>© {new Date().getFullYear()} {academicName}. All rights reserved.</p>
                        </div>
                        <div className="column is-6">
                            <p>
                                {"Facebook Pages: "}
                                <ExternalLink href={facebookLink} target="_blank">{facebookName}</ExternalLink>
                            </p>
                            <p>
                                {"Line: "}
                                <ExternalLink href={lineLink} target="_blank">{lineName}</ExternalLink>
                            </p>
                            {phoneNo ? <p>
                                {"Phone: "}
                                <ExternalLink href={`tel:${phoneNo}`} target="_blank">{phoneNo}</ExternalLink>
                            </p> : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </footer>
     );
}
 
export default Footer;