import { ExternalLink } from "react-external-link"

import './button.css'

export const ButtonSquarePrimary = (props) => {
  const { url, text, preIcon, sufIcon, isCenter, fullWidth } = props
  
  const centerClass = isCenter === "true" ? "button-center" : ""
  const widthClass = fullWidth === "true" ? "button-full-width" : ""

  return (
    <ExternalLink href={url} className={`button-square-primary ${centerClass} ${widthClass}`}>
      {preIcon && preIcon}<div className="text">{text}</div>{sufIcon && sufIcon}
    </ExternalLink>
  )
}

export const ButtonSquareWhite = (props) => {
  const { url, text, preIcon, sufIcon, isCenter, fullWidth } = props
  
  const centerClass = isCenter === "true" ? "button-center" : ""
  const widthClass = fullWidth === "true" ? "button-full-width" : ""

  return (
    <ExternalLink href={url} className={`button-square-white ${centerClass} ${widthClass}`}>
      {preIcon && preIcon}<div className="text">{text}</div>{sufIcon && sufIcon}
    </ExternalLink>
  )
}