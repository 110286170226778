import { useEffect, useState } from "react"
import { requestOptions } from "./inclassApi"

const useInclassCategoriesList = () => {
    const [odmCategories, setOdmCategories] = useState()

    useEffect(() => {
        async function getOdmCategories(options) {
            const response = await fetch(process.env.REACT_APP_INCLASS_ENDPOINT + "/public-api/course-categories", options)
            const result = await response.json()
            const categoriesList = result.results
            const reverseCategoriesList = categoriesList.reverse()
            setOdmCategories(reverseCategoriesList)
        }
        
        getOdmCategories(requestOptions);
    }, [])

    return odmCategories;
}
 
export default useInclassCategoriesList;