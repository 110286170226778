import { useEffect, useState } from "react"
import client from "../../client"

const useContentfulCourseData = () => {
  const [courseData, setCourseData] = useState(null)

  function clearCourseData(rawDatas) {
    const cleanDatas = rawDatas
      .map(rawData => {
        const { fields, sys } = rawData
        const { id } = sys
        let { 
          title, 
          bestSeller, 
          slug, 
          mobileImage, 
          desktopImage, 
          tutor, 
          odmTitle, 
          odmDescription, 
          timeAvailable, 
          videoDuration, 
          odmImage, 
          price,
          category,
          courseContents
        } = fields
        
        let mobileImageUrl = null
        if (!!mobileImage) {
          mobileImageUrl = mobileImage.fields.file.url
        }
        let desktopImageUrl = null
        if (!!desktopImage) {
          desktopImageUrl = desktopImage.fields.file.url
        }

        return { 
          id, 
          title, 
          bestSeller, 
          slug, 
          mobileImageUrl, 
          desktopImageUrl, 
          tutor, 
          odmTitle, 
          odmDescription, 
          timeAvailable, 
          videoDuration, 
          odmImage, 
          price,
          category,
          courseContents 
        }
      })

    setCourseData(cleanDatas)
  }

  useEffect(() => {
    async function getContentfulData(contentTypeId) {
      try {
        const response = await client.getEntries({ content_type: contentTypeId })
        const responseData = response.items
        clearCourseData(responseData)
      }
      catch (error) {
        console.log(error.message);
      }
    }
    
    getContentfulData('courses');
  }, [])

  return [courseData]
}

export default useContentfulCourseData;