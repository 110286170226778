import { Helmet } from "react-helmet-async";

const HelmetElement = (props) => {
    const { title } = props
    
    return (  
        <Helmet prioritizeSeoTags>
            <title>{title} | {process.env.REACT_APP_ACADEMIC_NAME}</title>
        </Helmet>
    );
}
 
export default HelmetElement;