import './SellPoint.css'

function SellPoint(props) {
    const {sellingPointTitle, sellingPoints} = props;
    
    const sellPointsElements = sellingPoints.map((sellPoint, index) => {
        return (
            <div className='sell-point-item' key={sellPoint.id}>
                <img className='sell-point-icon' src={sellPoint.iconUrl} alt={"icon" + index}/>
                <p className='sell-point-description'>{sellPoint.description}</p>
            </div>
        )
    })

    return (
        <section className='sell-point-section'>
            <div className='container sale-container'>
                <h2>{sellingPointTitle}</h2>
                <div className='grid grid-2 grid-desktop-4'>
                    {sellPointsElements}
                </div>
            </div>
        </section>
    )
}

export default SellPoint;