import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import XmarkIcon from "../svg/xmarkIcon";

import './searchInput.css'

const SearchInput = () => {
    const [ searchParam ] = useSearchParams()
    const [searchInput, setSearchInput] = useState(searchParam.get('search') ? searchParam.get('search') : "")
    
    function onInputChange(event) {
        setSearchInput(event.target.value)
    }

    function onClearInput(event) {
        setSearchInput("")
        const inputSelection = event.currentTarget.previousElementSibling;
        inputSelection.focus()
    }
    
    let clearButtonElement = null
    if (!!searchInput) {
        clearButtonElement = (
            <button className="clear-button" onClick={onClearInput}><XmarkIcon /></button>
        )
    }

    return (
        <div className="form-input">
            <Link to={`/searchcourse?search=${searchInput}`}><button type="submit"><img className="search-icon" src="/images/search-icon.svg" alt="search button"/></button></Link>
            <input
                type="text"
                placeholder="ค้นหาคอร์สเรียน"
                value={searchInput}
                onChange={onInputChange}
            />
            {clearButtonElement}
        </div>
    );
}
 
export default SearchInput;