import { useEffect, useState } from "react"
import { requestOptions } from "./inclassApi"

const useInclassCourseCount = () => {
    const [ odmCourseCount, setOdmCourseCount ] = useState()
    
    useEffect(() => {
      async function getOdmCourseCount(options) {
        try {
          const response = await fetch(process.env.REACT_APP_INCLASS_ENDPOINT + "/public-api/courses", options)
          const result = await response.json()
          const courseCount = result.count
          setOdmCourseCount(courseCount)
        } catch (error) {
          console.log(error);
        }
      }
      
      getOdmCourseCount(requestOptions)
    }, [])

    return odmCourseCount
}
 
export default useInclassCourseCount;