const CourseItem = (props) => {
    const { name, defaultHour, detail, price } = props
    let { imageUrl } = props

    let inclassImageElement = <img src={imageUrl} alt={`${name} thumbnail`} />

    if (!imageUrl) {
        inclassImageElement = <img src="/images/course-no-thumbnail.jpg" alt={`${name} no-thumbnail`} />
    }

    return (
        <div className="course-card">
            <div className="course-card-content">
                { inclassImageElement }
                <div className="detail">
                    <p>{name}</p>
                    <p>เรียนได้ทั้งหมด {defaultHour} ชั่วโมง</p>
                    <p className="truncate">
                        {detail}
                    </p>
                    <p>ราคา <strong>{price.toLocaleString()}</strong>.-</p>
                </div>
            </div>
        </div>
    );
}
 
export default CourseItem;