import { useEffect, useState } from "react"
import { requestOptions } from "./inclassApi"

export const useInclassCourseDetail = (courseId) => {
  const [courseData, setCourseData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const idNumber = Number(courseId)

  useEffect(() => {
    async function getOdmCourseDetail(courseId, options) {
      if (Number.isInteger(idNumber)) {
        setIsLoading(true)
        const response = await fetch(process.env.REACT_APP_INCLASS_ENDPOINT + "/public-api/courses/" + courseId, options)
        const result = await response.json()
        setCourseData(result)
        setIsLoading(false)
      } else {
        setCourseData({detail: 'Not found.'})
      }
    }
    
    getOdmCourseDetail(idNumber, requestOptions)
  }, [idNumber])

  return [ courseData, isLoading ]
}