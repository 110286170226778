import './CoverPage.css'

function CoverPage(props) {
    const {coverPageUrl, academicName} = props

    return (
        <section className="cover-page-section">
            <h1>{academicName}</h1>
            <div className='bg-container'>
                <img className='cover-bg' src={coverPageUrl} alt="cover background"/>
            </div>
            <img className='cover-image' src={coverPageUrl} alt="cover page"/>
        </section>
    )
}

export default CoverPage;