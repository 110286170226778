const SearchIcon = () => {
  return (
    <svg width="20px" height="20px" viewBox="0 0 22 22" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Page-1" stroke="none" strokeWidth="1">
        <g id="iPhone-13-Pro-Copy" transform="translate(-47.000000, -87.000000)">
            <g id="icon" transform="translate(47.000000, 87.000000)">
                <path d="M21.497296,19.0631846 L16.3539175,13.9203742 C17.5235312,12.1841924 18.1006036,10.0149317 17.7916572,7.70345974 C17.2648583,3.77180913 14.0421901,0.572267868 10.106238,0.0740124627 C4.25430211,-0.666388969 -0.666497451,4.25386705 0.0740287427,10.1051565 C0.572468098,14.0423924 3.77279251,17.2672826 7.70530714,17.7914454 C10.0170345,18.1003147 12.1869644,17.5235208 13.9229084,16.3538644 L19.0662869,21.4966748 C19.7374613,22.1677751 20.8258638,22.1677751 21.4970382,21.4966748 C22.167611,20.8247153 22.167611,19.7334255 21.497296,19.0631846 Z M3.39879112,8.93653124 C3.39879112,5.90412057 5.86606592,3.4371183 8.89881159,3.4371183 C11.9315572,3.4371183 14.3988321,5.90412057 14.3988321,8.93653124 C14.3988321,11.9689419 11.9315572,14.4359442 8.89881159,14.4359442 C5.86606592,14.4359442 3.39879112,11.9698012 3.39879112,8.93653124 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg>
  );
}
 
export default SearchIcon;