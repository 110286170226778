import { Link } from "react-router-dom";

const FreeTrialButton = (props) => {
    const { trialButton, courseSlug } = props
    if (trialButton === "show") {return (
        <Link to={"/courses/" + courseSlug} className="white-button">
            <span>ดูเนื้อหา</span>
            <img src="/images/play-icon.svg" width='18px;'alt="play-icon"/>
        </Link>
    )};
    return null
}
 
export default FreeTrialButton;