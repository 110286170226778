import { HelmetProvider } from "react-helmet-async";
import { useSearchParams } from "react-router-dom";
import AllCourses from "./allCourses/AllCourses";
import AppCourseSearchInput from "./AppCourseSearchInput";
import SearchInput from "./courseSearch/searchInput";
import HelmetElement from "./HelmetElement";
import Loader from "./Loading";

const AppCourseSearch = (props) => {
  const { isLoading, fbMessengerLink } = props

  const [searchParam] = useSearchParams()
  const searchValue = searchParam.get('search')

  return (
    <HelmetProvider>
      <HelmetElement title={searchValue ? `ค้นหา ${searchValue}` : "ค้นหาวิชาเรียน"} />
      <div>
        {isLoading && <Loader />}
        <section className="search-section">
          <form className="search-form">
            <label><h1>ค้นหาวิชาเรียน</h1></label>
            <SearchInput />
          </form>
        </section>
        {searchValue ?
          <AppCourseSearchInput isLoading={isLoading} fbMessengerLink={fbMessengerLink} />
          :
          <section className="all-course-section">
            <div className="container">
              <h2>วิชาเรียนทั้งหมด</h2>
              <AllCourses />
            </div>
          </section>
        }
      </div>
    </HelmetProvider>
  );
}

export default AppCourseSearch;