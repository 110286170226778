import React from 'react'
import FreeTrialButton from './FreeTrialButton'

export default function CourseItemSalePage(props) {
  const { course, trialButton } = props
  
  function categoryDetact(categorise) {
    return (
        categorise && categorise.map((item, index) => {
            if (index === 0) return (<span key={index}>{item}</span>)
            return (<span key={index}> &#183; {item}</span>)
        })
    )
}

  return (
    <div className="course-item">
      <img src={course.mobileImageUrl} alt="mobile" className="mobile-image" />
      <img src={course.desktopImageUrl} alt="desktop" className="desktop-image" />
      <h3>{course.title}</h3>
      <div className="bottom-content">
        {course.slug && <FreeTrialButton trialButton={trialButton} courseSlug={course.slug} />}
        <p>{categoryDetact(course.category)}{course.tutor && " | "}{course.tutor && <span className="tutor-text">โดย {course.tutor}</span>}</p>
      </div>
    </div>
  )
}
