import './CourseSlider.css'
import styled, { keyframes } from 'styled-components';
import { Link } from 'react-router-dom';

function CourseSlider(props) {
  const { courseData } = props;

  const courseAmount = courseData.length

  const CourseSliderElement = courseData.map(course => {
    if (!!course.desktopImageUrl) {
      return (
        <div className='slider-item' key={course.id}>
          <img src={course.desktopImageUrl} alt="desktop" width="357" />
          <h4>{course.title}</h4>
          { course.slug && 
            <div className="course-overlay">
              <Link to={`/courses/${course.slug}`} className="white-button animate"><span>ดูเนื้อหา</span><img src="/images/play-icon.svg" width='18' alt="play-icon" /></Link>
            </div>
          }
        </div>
      )
    }
    return null
  })

  const ReverseSliderElement = courseData.reverse().map(course => {
    if (!!course.desktopImageUrl) {
      return (
        <div className='slider-item' key={course.id}>
          <img src={course.desktopImageUrl} alt="desktop" width="357" />
          <h4>{course.title}</h4>
          { course.slug && 
            <div className="course-overlay">
              <Link to={`/courses/${course.slug}`} className="white-button animate"><span>ดูเนื้อหา</span><img src="/images/play-icon.svg" width='18' alt="play-icon" /></Link>
            </div>
          }
        </div>
      )
    }
    return null
  })

  return (
    <div className="slider">
      { courseAmount >= 4 ?
        <CourseTrack className="course-track" courseAmount={courseData.length} slideSpeed={100}>
          {CourseSliderElement}
          {CourseSliderElement}
        </CourseTrack>
        : ""
      }
      { courseAmount >= 8 ?
        <CourseTrack className="course-track" courseAmount={courseData.length} slideSpeed={200}>
          {ReverseSliderElement}
          {ReverseSliderElement}
        </CourseTrack>
        : ""
      }
    </div>
  )
}

const scroll = (amountSlide, slideWidth) => keyframes`
    0% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(${-1 * amountSlide * slideWidth}px);
	}
`

const CourseTrack = styled.div`
    width: ${props => props.courseAmount * 2 * 190}px;
    animation: 
        ${props => scroll(props.courseAmount, 200)} 
        ${props => props.slideSpeed}s 
        linear 
        infinite;
    
    @media screen and (min-width: 1069px){
        width: ${props => props.courseAmount * 2 * 377}px;
        animation: 
            ${props => scroll(props.courseAmount, 377)} 
            ${props => props.slideSpeed}s 
            linear 
            infinite;
    
    }
`;

export default CourseSlider;