import { useState } from 'react';
import { ExternalLink } from 'react-external-link';
import { Link } from 'react-router-dom';
import './Navbar.css'

const Navbar = (props) => {
    const { courseAmount, contactError } = props
    const [menuActive, setMenuActive] = useState("")

    const toggleMenu = () => {
        if (menuActive === "") {
            setMenuActive("is-active")
            return
        }
        setMenuActive("")
    }

    const removeActiveMenu = () => {
        setMenuActive("")
    }

    
    return ( 
        <nav className='nav has-shadow' id='dashboard-navbar'>
            <div className="nav-container">
                <div className="nav-left">
                    <Link to="/" className="nav-item" onClick={removeActiveMenu}>
                        <img src={"/images/logo/" + process.env.REACT_APP_LOGO} alt="Logo" />
                    </Link>
                </div>
                <ExternalLink href={process.env.REACT_APP_ACADEMIC_INCLASS_URL} className={`button login-bn ${menuActive} is-primary is-outlined`}>ล็อกอิน</ExternalLink>
                <span className={`nav-toggle ${menuActive}`} onClick={toggleMenu}>
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
                <div className={`nav-right nav-menu ${menuActive}`}>
                    {courseAmount >= 3 ?
                        (<>
                            <Link to="/courses" className="nav-item" onClick={removeActiveMenu}>คอร์สทั้งหมด</Link>
                            <Link to="/searchcourse" className="nav-item" onClick={removeActiveMenu}>ค้นหาคอร์ส</Link>
                        </>)
                        : null
                    }
                    {!contactError && <Link to="/contact" className="nav-item" onClick={removeActiveMenu}>ติดต่อเรา</Link>}
                    <ExternalLink href={process.env.REACT_APP_ACADEMIC_INCLASS_URL} className="nav-item" onClick={removeActiveMenu}>เข้าสู่ระบบ</ExternalLink>
                    <div className="nav-item">
                        <ExternalLink href={process.env.REACT_APP_ACADEMIC_INCLASS_URL + "/register"} className="button begr-button is-primary is-outlined" onClick={removeActiveMenu}>สมัครสมาชิก</ExternalLink>
                    </div>
                </div>
            </div>
        </nav>
    );
}
 
export default Navbar;