const CourseContents = (props) => {
  const { courseContents } = props

  return (
    <div className="table-container">
      <table className="table">
        <thead>
          <tr>
            <th style={{ width: "40px" }}></th>
            <th>รายละเอียดวิชา</th>
          </tr>
        </thead>
        <tbody>
          {
            courseContents && courseContents.map((content, index) => {
              return (
                <tr key={index}>
                  <td style={{ textAlign: "right" }}>{index + 1}</td>
                  <td>{content}</td>
                </tr>
              )
            })
          }
        </tbody>
      </table>
    </div>
  );
}

export default CourseContents;