import CourseCarousel from "./salePage/CourseCarousel";

import './AppAllCourse.css'
import SearchInput from "./courseSearch/searchInput";
import AllCourses from "./allCourses/AllCourses";
import Loader from "./Loading";
import { HelmetProvider } from "react-helmet-async";
import HelmetElement from "./HelmetElement";

const AppAllCourse = (props) => {
    const { isLoading, courseData, courseCategories } = props;
    const bestSellCourses = courseData.filter(rawData => rawData.bestSeller)
    const courseBestSellAmount = bestSellCourses.length
    
    let loadingElement = null
    if (isLoading) {
        loadingElement = (
            <Loader />
        )
    }

    return (
        <HelmetProvider>
            <HelmetElement title="คอร์สเรียนทั้งหมด" />
            <div>
                {loadingElement}
                { courseBestSellAmount >= 3 ?
                    <section className="course-section">
                        <h2>คอร์สเรียนยอดนิยม</h2>
                        <CourseCarousel courseData={courseData} trialButton={"show"} />
                    </section>
                    : null
                }
                <section className="search-section">
                    <form className="search-form">
                        <label><h2>ค้นหาวิชาเรียน</h2></label>
                        <SearchInput />
                    </form>
                </section>
                <section className="all-course-section">
                    <div className="container">
                        <h1>วิชาเรียนทั้งหมด</h1>
                        <AllCourses courseData={courseData} courseCategories={courseCategories} />
                    </div>
                </section>
            </div>
        </HelmetProvider>
        
    );
}
 
export default AppAllCourse;