import { Link, useSearchParams } from "react-router-dom";
import CourseItem from "./allCourses/CourseItem";
import './AppCourseSearchInput.css'
import { ExternalLink } from "react-external-link";
import MessengerIcon from "./svg/messengerIcon";
import Loader from "./Loading";

import { useInclassCourseSearch } from "./api/inclassCourseSearch";


const AppCourseSearchInput = (props) => {
  const { fbMessengerLink } = props;
  const [searchParam] = useSearchParams()
  const courseSearch = searchParam.get('search')

  // Get Course Search Data
  const [ courseFilter, courseAmount, isLoading] = useInclassCourseSearch(courseSearch)

  let searchResultElement = null
  if (courseFilter && courseAmount !== 0) {
    searchResultElement =
      <div>
        <h2>ค้นหา "{courseSearch}"</h2>
        <div className="grid grid-ipad-3">
          {courseFilter
            .map(course => {
              const { id, image, name, default_hours, detail, price } = course
              return (
                <Link to={`/courses/${id}`} key={id}>
                  <CourseItem
                    imageUrl={image}
                    name={name}
                    defaultHour={default_hours/60}
                    detail={detail}
                    price={Math.round(price)}
                  />
                </Link>
              )
            })
          }
        </div>
      </div>
  }

  let NoResultElement = null
  if (courseAmount === 0) {
    NoResultElement = (
      <div className="search-not-found">
        <img src="/images/not-found-icon.svg" alt="not found" />
        <h2>ไม่เจอวิชา "{courseSearch}"</h2>
        <ExternalLink href={fbMessengerLink} className="button bt-gray">สอบถามคอร์สเรียน<MessengerIcon /></ExternalLink>
      </div>
    )
  }

  return (
    <div>
      {isLoading && <Loader />}
      <section className="search-result">
        <div className="container">
          {courseFilter && searchResultElement}
          {courseFilter && NoResultElement}
        </div>
      </section>
    </div>
  );
}

export default AppCourseSearchInput;