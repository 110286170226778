import { useEffect, useState } from "react";
import client from "../../client";

const useContentfulSalepage = (contentTypeId) => {
  const [isLoading, setIsLoading] = useState(false);
  const [salePageData, setSalePageData] = useState(null);

  function clearData(rawDatas) {
    const cleanDatas = rawDatas.map((data) => {
      const { fields } = data;
      const {
        academicName,
        coverPage,
        sellingPointTitle,
        sellingPointInfo,
        testimonialTitle,
        testimonialInfo,
        facebookName,
        facebookLink,
        fbMessengerLink,
        lineName,
        lineLink,
        phoneNo,
      } = fields;
      const coverPageUrl = coverPage.fields.file.url;
      const sellingPoints = sellingPointInfo.map((info) => {
        const id = info.sys.id;
        const description = info.fields.description;
        const iconUrl = info.fields.icon.fields.file.url;
        return { id, description, iconUrl };
      });
      const testimonials =
        testimonialInfo &&
        testimonialInfo
          .filter((info) => info.fields)
          .map((info) => {
            const id = info.sys.id;
            const name = info.fields.name && info.fields.name;
            const review = info.fields.review;
            const avatarUrl =
              info.fields.avatar && info.fields.avatar.fields.file.url;
            return { id, name, review, avatarUrl };
          });

      return {
        academicName,
        coverPageUrl,
        sellingPointTitle,
        sellingPoints,
        testimonialTitle,
        testimonials,
        facebookName,
        facebookLink,
        fbMessengerLink,
        lineName,
        lineLink,
        phoneNo,
      };
    });
    setSalePageData(cleanDatas[0]);
  }

  useEffect(() => {
    async function getSalePageData(contentTypeId) {
      setIsLoading(true);
      try {
        const response = await client.getEntries({
          content_type: contentTypeId,
        });
        const responseData = response.items;
        setIsLoading(false);
        clearData(responseData);
      } catch (error) {
        console.log(error);
      }
      setIsLoading(false);
    }

    getSalePageData(contentTypeId);
  }, [contentTypeId]);

  return [salePageData, isLoading];
};
export default useContentfulSalepage;
