import { useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import './App.css';

import AppAllCourse from './components/AppAllCourse';
import AppCourseDetail from './components/AppCourseDetail';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import AppSalePage from './components/AppSalePage';
import AppCourseSearch from './components/AppCourseSearch';
import useContentfulSalepage from './components/api/ContentfulSalepage';
import useContentfulCourseData from './components/api/ContentfulCourseData';
import useInclassCourseCount from './components/api/inclassCourseCount';
import useContentfulContactUs from './components/api/ContentfulContactUs';
import AppContactUs from './components/AppContactUs';

function App() {
  // Move to top when navigate
  const location = useLocation()
  
  useEffect(() => {
    window.scrollTo(0,0)
  }, [location.pathname])

  // Get Contentful Data
  const [ salePageData, isLoading ] = useContentfulSalepage(process.env.REACT_APP_SALEPAGE_CONTENT_TYPE_ID)
  const [ courseData ] = useContentfulCourseData()
  const [ contactLoading, contactError, contactData ] = useContentfulContactUs()

  // Get Inclass Course Count
  const inclassCourseCount = useInclassCourseCount()
  const inclassCourseAmount = inclassCourseCount && inclassCourseCount

  return (
    <div className="App">
      <Navbar courseAmount={inclassCourseAmount} contactError={contactError}/>
      <div className="app-content">
        <Routes>
          <Route path="/" element={courseData && salePageData && <AppSalePage isLoading={isLoading} salePageData={salePageData} courseData={courseData} courseAmount={inclassCourseAmount}/>} />
          <Route path="/courses" element={courseData && <AppAllCourse isLoading={isLoading} courseData={courseData}/>} />
          <Route path='/courses/:id' element={salePageData && <AppCourseDetail fbMessengerLink={salePageData.fbMessengerLink}/>} />
          <Route path='/searchcourse' element={salePageData && <AppCourseSearch fbMessengerLink={salePageData.fbMessengerLink}/>} />
          {contactData && <Route path='/contact' element={<AppContactUs isLoading={contactLoading} contactUsData={contactData}/>}/>}
        </Routes>
      </div>
      {salePageData && <Footer salePageData={salePageData}/>}
    </div>
  );
}

export default App;
