import { useEffect, useState } from "react";
import client from "../../client";

const useContentfulContactUs = () => {
  const [ isLoading, setIsLoading ] = useState(false)
  const [ contactUsData, setContactUsData ] = useState()
  const [ error, setError ] = useState(null)
  
  function cleanContactUsData(rawData) {
    const [ data ] = rawData
    const { 
      pageTitle,
      contactItems
    } = data.fields

    const contactUsList = contactItems.map(contact => {
      const id = contact.sys.id
      const title = contact.fields.title
      const url = contact.fields.url
      const icon = contact.fields.icon.fields.file.url
      return { id, title, url, icon }
    })

    setContactUsData({pageTitle, contactUsList})
  }
  
  useEffect(() => {
    async function getContentfulData(contentTypeId) {
      setIsLoading(true)
      try {
          const response = await client.getEntries({ content_type: contentTypeId })
          const responseData = response.items
          cleanContactUsData(responseData)
          setIsLoading(false)
      }
      catch (error) {
          setError(error.message)
          setIsLoading(false)
      }
    }
    getContentfulData('contactUsPage');
  }, [])
  
  return [ isLoading, error, contactUsData ];
}
 
export default useContentfulContactUs;